import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import api from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import SimpleSteps from "../../components/SimpleSteps";
import TextInput from "../../components/TextInput";
import RadioGroupList from "../../components/RadioGroupList";
import SelectInput from "../../components/SelectInput";
import MoneyInput from "../../components/MoneyInput";
import DateInput from "../../components/DateInput";
import Checkbox from "../../components/Checkbox";
import InlineCheckbox from "../../components/InlineCheckbox";

const categories = [
    "Efectivo y productos financieros",
    "Pagarés y bonos de renta fija",
    "Participación en empresas",
    "Productos estructurados",
    "Bienes raíces",
    "Activos alternativos directos",
];

const categoriesIdProd = [3, 4, 5, 6, 2, 1];

const categoriesIdDev = [1, 2, 3, 4, 5, 6];

const risksTFO = [
    <option value={1} label="a">
        a
    </option>,
    <option value={2} label="b">
        b
    </option>,
    <option value={3} label="c">
        c
    </option>,
    <option value={4} label="d">
        d
    </option>,
    <option value={5} label="e">
        e
    </option>,
];

const risks = [
    <option value={0} label="Ninguno">
        Ninguno
    </option>,
    <option value={1} label="AAA">
        AAA
    </option>,
    <option value={2} label="AAA-">
        AAA-
    </option>,
    <option value={3} label="AA+">
        AA+
    </option>,
    <option value={4} label="AA">
        AA
    </option>,
    <option value={5} label="AA-">
        AA-
    </option>,
    <option value={6} label="A+">
        A+
    </option>,
    <option value={7} label="A">
        A
    </option>,
    <option value={8} label="A-">
        A-
    </option>,
    <option value={9} label="BBB+">
        BBB+
    </option>,
    <option value={10} label="BBB">
        BBB
    </option>,
    <option value={11} label="BBB-">
        BBB-
    </option>,
    <option value={12} label="BB+">
        BB+
    </option>,
    <option value={13} label="BB">
        BB
    </option>,
    <option value={14} label="BB-">
        BB-
    </option>,
    <option value={15} label="B+">
        B+
    </option>,
    <option value={16} label="B">
        B
    </option>,
    <option value={17} label="B-">
        B-
    </option>,
    <option value={18} label="CCC+">
        CCC+
    </option>,
    <option value={19} label="CCC">
        CCC
    </option>,
    <option value={20} label="CCC-">
        CCC-
    </option>,
    <option value={21} label="CC+">
        CC+
    </option>,
    <option value={22} label="CC">
        CC
    </option>,
    <option value={23} label="CC-">
        CC-
    </option>,
    <option value={24} label="C+">
        C+
    </option>,
    <option value={25} label="C">
        C
    </option>,
    <option value={26} label="C-">
        C-
    </option>,
    <option value={27} label="D">
        D
    </option>,
];

export default function NewClientAssetPage() {
    const today = new Date();
    const { idClient } = useParams();
    const { idCategoryAsset } = useParams();
    const { idClientAsset } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [assets, setAssets] = useState([]);
    const [client, setClient] = useState([]);
    const [countries, setCountries] = useState([]);
    const [entities, setEntities] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [funds, setFunds] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const pages = idClientAsset
        ? [
              { name: `Clientes`, href: `/clients`, current: false },
              {
                  name: `Panel de control`,
                  href: `/clients/${idClient}`,
                  current: false,
              },
              {
                  name: "Activos",
                  href: `/clients/${idClient}/assets`,
                  current: false,
              },
              { name: "Editar activo", href: "#", current: true },
          ]
        : [
              { name: `Clientes`, href: `/clients`, current: false },
              {
                  name: `Panel de control`,
                  href: `/clients/${idClient}`,
                  current: false,
              },
              { name: "Nuevo activo", href: "#", current: true },
          ];
    const [steps, setSteps] = useState([
        { id: "Paso 1", name: "Activo a cargar", status: "current" },
        { id: "Paso 2", name: "Formulario", status: "upcoming" },
    ]);
    const [selected, setSelected] = useState(null);
    const [asset, setAsset] = useState({
        client: idClient,
        familyMembers: null,
        asset: null,
        currency: "1",
        amount: "",
        property: false,
        todaysDate: today.toISOString().substring(0, 10),
    });
    const [errors, setErrors] = useState({
        familyMembers: "",
        property: "",
        stock: "",
        investmentFund: "",
        asset: "",
        notes: "",
        currency: "",
        amount: "",
        entity: "",
        country: "",
        investmentSector: "",
        nominalRate: "",
        capitalizationPeriod: "",
        rent: "",
        purchasePrice: "",
        actualPrice: "",
        profitOrLoss: "",
        ratingRiskPy: "",
        ratingRiskIntl: "",
        tfoRisk: "",
        originalDeadline: "",
        investmentDate: "",
        expirationDate: "",
        todaysDate: "",
        daysToExpiration: "",
    });

    const handleChange = (field, value) =>
        setAsset((prev) => ({ ...prev, [field]: value }));
    const handleExtraData = (field, value) =>
        setAsset((prev) => ({
            ...prev,
            ["extraData"]: { ...prev.extraData, [field]: value },
        }));
    const handleError = (field, value) =>
        setErrors((prev) => ({ ...prev, [field]: value }));

    const handleFamilyMember = (field, value) => {
        setAsset((prev) => ({ ...prev, [field]: [value] }));
    };

    const handleFamilyMembers = (field, value, id) => {
        let obj = asset.familyMembers || [];
        if (value) {
            obj.push(id);
        } else {
            obj.splice(obj.indexOf(id), 1);
        }
        console.log(obj);
        handleChange(field, obj);
    };

    function numberWithSeparators(x) {
        return x
            .toString()
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const fetchAssets = async () => {
        await api.assets
            .getMulti(null, null, null, null, {
                category:
                    process.env.REACT_APP_ENV === "dev"
                        ? categoriesIdDev[idCategoryAsset - 1]
                        : categoriesIdProd[idCategoryAsset - 1],
            })
            .then((response) => {
                setAssets(response);
            })
            .catch((error) => console.log(error));
    };

    const fetchAsset = async () => {
        await api.clientAssets
            .getOne(idClientAsset)
            .then((response) => {
                setAsset(response);
            })
            .catch((error) => console.log(error));
    };

    const fetchSelected = async () => {
        await api.assets
            .getOne(asset.asset)
            .then((response) => {
                setSelected(response);
            })
            .catch((error) => console.log(error));
    };

    const fetchConfig = async () => {
        await api.countries
            .getMulti()
            .then((response) => {
                setCountries(response);
            })
            .catch((error) => console.log(error));

        await api.entities
            .getMulti()
            .then((response) => {
                setEntities(response);
            })
            .catch((error) => console.log(error));

        await api.investmentSectors
            .getMulti()
            .then((response) => {
                setSectors(response);
            })
            .catch((error) => console.log(error));

        await api.stocks
            .getMulti()
            .then((response) => {
                setStocks(response);
            })
            .catch((error) => console.log(error));

        await api.funds
            .getMulti()
            .then((response) => {
                setFunds(response);
            })
            .catch((error) => console.log(error));

        await api.currencies
            .getMulti()
            .then((response) => {
                setCurrencies(response);
            })
            .catch((error) => console.log(error));
    };

    const fetchClient = async () => {
        await api.clients
            .getOne(idClient)
            .then((response) => {
                setClient(response);
            })
            .catch((error) => console.log(error));
    };

    const calculateDifference = (date1, date2) => {
        date2 = new Date(date2);
        date1 = new Date(date1);
        let difference = date2.getTime() - date1.getTime();
        let days = Math.ceil(difference / (1000 * 3600 * 24));
        return days;
    };

    const goNext = () => {
        setSteps([
            { id: "Paso 1", name: "Activo a cargar", status: "complete" },
            { id: "Paso 2", name: "Formulario", status: "current" },
        ]);
    };

    useEffect(() => {
        if (idClientAsset) {
            fetchAsset();
            fetchClient();
            fetchConfig();
            setLoading(false);
        } else {
            fetchAssets();
            fetchClient();
            fetchConfig();
        }
    }, []);

    useEffect(() => {
        fetchSelected();
    }, [asset.id]);

    useEffect(() => {
        if (asset && client && selected) {
            setLoading(false);
        }
    }, [asset, client, selected]);

    useEffect(() => {
        setSelected(assets[0]);
    }, [assets]);

    useEffect(() => {
        handleChange("asset", selected?.id);
    }, [selected]);

    useEffect(() => {
        if (client.length > 0) {
            handleChange("familyMembers", client.members[0].familyMemberId);
        }
    }, [client]);

    useEffect(() => {
        if (!asset.expirationDate) {
            handleChange("expirationDate", null);
        }
        if (!asset.investmentDate) {
            handleChange(
                "investmentDate",
                today.toISOString().substring(0, 10)
            );
        }
    }, [selected]);

    useEffect(() => {
        if (asset.ratingRiskIntl) {
            handleChange("ratingRiskPy", null);
        }
    }, [asset.ratingRiskIntl]);

    useEffect(() => {
        if (asset.ratingRiskPy) {
            handleChange("ratingRiskIntl", null);
        }
    }, [asset.ratingRiskPy]);

    useEffect(() => {
        if (asset.amount && asset.nominalRate) {
            handleChange(
                "rent",
                Math.round(
                    ((asset.amount * (asset.nominalRate / 100)) / 12) * 100
                ) / 100
            );
        } else {
            handleChange("rent", null);
        }
    }, [asset.amount, asset.nominalRate]);

    useEffect(() => {
        if (asset.purchasePrice && asset.actualPrice) {
            handleChange(
                "profitOrLoss",
                asset.actualPrice - asset.purchasePrice
            );
        } else {
            handleChange("profitOrLoss", null);
        }
    }, [asset.purchasePrice, asset.actualPrice]);

    useEffect(() => {
        if (asset.expirationDate && asset.investmentDate) {
            handleChange(
                "originalDeadline",
                calculateDifference(asset.investmentDate, asset.expirationDate)
            );
        } else {
            handleChange("originalDeadline", null);
        }
    }, [asset.expirationDate, asset.investmentDate]);

    const handleSubmit = async () => {
        let error = false;
        if (!asset.familyMembers || asset.familyMembers.length === 0) {
            handleError("familyMembers", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("familyMembers", "");
        }
        if (selected.stock && !asset.stock && !asset.extraData) {
            handleError("stock", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("stock", "");
        }
        if (selected.investmentFund && !asset.investmentFund) {
            handleError("investmentFund", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("investmentFund", "");
        }
        if (selected.amount && !asset.amount) {
            handleError("amount", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("amount", "");
        }
        if (selected.entity && !asset.entity) {
            handleError("entity", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("entity", "");
        }
        if (selected.country && !asset.country) {
            handleError("country", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("country", "");
        }
        if (selected.investmentSector && !asset.investmentSector) {
            handleError("investmentSector", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("investmentSector", "");
        }
        if (selected.nominalRate && !asset.nominalRate) {
            handleError("nominalRate", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("nominalRate", "");
        }
        if (selected.capitalizationPeriod && !asset.capitalizationPeriod) {
            handleError("capitalizationPeriod", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("capitalizationPeriod", "");
        }
        if (selected.purchasePrice && !asset.purchasePrice) {
            handleError("purchasePrice", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("purchasePrice", "");
        }
        if (selected.actualPrice && !asset.actualPrice) {
            handleError("actualPrice", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("actualPrice", "");
        }
        if (
            selected.profitOrLoss &&
            !asset.profitOrLoss &&
            asset.profitOrLoss !== 0
        ) {
            handleError("profitOrLoss", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("profitOrLoss", "");
        }
        if (!asset.ratingRiskPy && !asset.ratingRiskIntl) {
            handleError("ratingRiskPy", "Debe completar el riesgo PY o Intl");
            error = true;
        } else {
            handleError("ratingRiskPy", "");
        }
        if (selected.tfoRisk && !asset.tfoRisk) {
            handleError("tfoRisk", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("tfoRisk", "");
        }
        if (selected.investmentDate && !asset.investmentDate) {
            handleError("investmentDate", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("investmentDate", "");
        }
        if (selected.expirationDate && !asset.expirationDate) {
            handleError("expirationDate", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("expirationDate", "");
        }
        if (selected.todaysDate && !asset.todaysDate) {
            handleError("todaysDate", "Este campo es obligatorio");
            error = true;
        } else {
            handleError("todaysDate", "");
        }
        if (!error) {
            if (idClientAsset) {
                api.clientAssets
                    .update(idClientAsset, asset)
                    .catch((response) => {
                        console.log(response);
                        for (var element in response.data) {
                            error = true;
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    response.data,
                                    element
                                )
                            ) {
                                if (
                                    response.data[element] !=
                                    "Este campo no puede estar en blanco."
                                )
                                    handleError(
                                        element,
                                        response.data[element]
                                    );
                            }
                        }
                    })
                    .then(() =>
                        error ? null : navigate(`/clients/${idClient}/assets`)
                    );
            } else {
                api.clientAssets
                    .create(asset)
                    .catch((response) => {
                        for (var element in response.data) {
                            error = true;
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    response.data,
                                    element
                                )
                            ) {
                                if (
                                    response.data[element] !=
                                    "Este campo no puede estar en blanco."
                                )
                                    handleError(
                                        element,
                                        response.data[element]
                                    );
                            }
                        }
                    })
                    .then(() =>
                        error ? null : navigate(`/clients/${idClient}/assets`)
                    );
            }
        }
        // }
    };

    return (
        <div className="min-h-screen select-none h-full bg-gray-50">
            <Header current="clients" />
            {loading ? (
                <div className="flex h-screen items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div>
            ) : (
                <>
                    <div className="flex py-6 bg-white justify-between">
                        <div className="flex flex-col sm:px-24 space-y-2 lg:flex-shrink-0">
                            <Breadcrumbs pages={pages} />
                            <h1 className="font-bold text-3xl ml-4">
                                {categories[idCategoryAsset - 1]}
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-col mt-2 px-2 sm:px-24 pb-6 space-y-4">
                        <div className="bg-white overflow-hidden shadow rounded-lg">
                            <div className="px-4 py-5 sm:p-6">
                                {steps[0].status === "current" &&
                                !idClientAsset ? (
                                    <div className="flex flex-col">
                                        <div className="flex justify-between">
                                            <div className="w-10/12">
                                                <SimpleSteps steps={steps} />
                                            </div>
                                            <button
                                                onClick={() => goNext()}
                                                className="inline-flex items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900"
                                            >
                                                Siguiente
                                            </button>
                                        </div>
                                        <div className="mt-6 w-3/4">
                                            <RadioGroupList
                                                selected={selected}
                                                settings={assets}
                                                setSelected={setSelected}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {steps[1].status === "current" ||
                                idClientAsset ? (
                                    <>
                                        {loading ? (
                                            <div className="flex h-screen items-center justify-center ">
                                                <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                                            </div>
                                        ) : (
                                            <div className="flex flex-col">
                                                <div className="flex justify-between">
                                                    {!idClientAsset ? (
                                                        <>
                                                            <div className="w-10/12">
                                                                <SimpleSteps
                                                                    steps={
                                                                        steps
                                                                    }
                                                                />
                                                            </div>
                                                            <button
                                                                onClick={() =>
                                                                    handleSubmit()
                                                                }
                                                                className="inline-flex items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900"
                                                            >
                                                                Finalizar
                                                            </button>
                                                        </>
                                                    ) : null}
                                                </div>
                                                <div className="mt-6 sm:w-3/5 space-y-4">
                                                    {!idClientAsset ? (
                                                        <p className="font-bold text-2xl text-gray-700">
                                                            {selected?.name}
                                                        </p>
                                                    ) : (
                                                        <div className="flex justify-between">
                                                            <p className="font-bold text-2xl text-gray-700">
                                                                {selected?.name}
                                                            </p>
                                                            <button
                                                                onClick={() =>
                                                                    handleSubmit()
                                                                }
                                                                className="inline-flex items-center h-10 px-4 py-2 border border-gray-300 shadow-sm text-sm font-semibold rounded-md text-white bg-blue-800 hover:bg-blue-900"
                                                            >
                                                                Finalizar
                                                            </button>
                                                        </div>
                                                    )}
                                                    <p className="font-medium text-lg text-gray-700">
                                                        Completa el formulario
                                                    </p>
                                                    <div>
                                                        <p className="text-blue-800 font-semibold text-md mt-2">
                                                            Datos generales
                                                        </p>
                                                        {selected?.name ===
                                                        "Certificados de Depositos de Ahorro" ? (
                                                            <>
                                                                <p className="text-sm font-medium text-gray-700 mt-4">
                                                                    Titulares
                                                                    del activo
                                                                </p>
                                                                <div className="grid grid-cols-3 gap-x-8 -mt-2">
                                                                    {client.members.map(
                                                                        (
                                                                            member
                                                                        ) => (
                                                                            <InlineCheckbox
                                                                                value={
                                                                                    asset.familyMembers
                                                                                        ? asset.familyMembers.includes(
                                                                                              member.familyMemberId
                                                                                          )
                                                                                        : false
                                                                                }
                                                                                handleChange={
                                                                                    handleFamilyMembers
                                                                                }
                                                                                label={
                                                                                    member.firstName +
                                                                                    " " +
                                                                                    member.lastName
                                                                                }
                                                                                name="familyMembers"
                                                                                idx={
                                                                                    member.familyMemberId
                                                                                }
                                                                            />
                                                                        )
                                                                    )}
                                                                    {errors.familyMembers && (
                                                                        <p className="mt-2 text-sm text-red-600">
                                                                            {
                                                                                errors.familyMembers
                                                                            }
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <SelectInput
                                                                errorMessage={
                                                                    errors.familyMembers
                                                                }
                                                                name="familyMembers"
                                                                value={
                                                                    asset.familyMembers
                                                                }
                                                                handleChange={
                                                                    handleFamilyMember
                                                                }
                                                                options={
                                                                    client.members
                                                                        ? client.members.map(
                                                                              (
                                                                                  element
                                                                              ) => {
                                                                                  return (
                                                                                      <option
                                                                                          key={
                                                                                              element.familyMemberId
                                                                                          }
                                                                                          value={
                                                                                              element.familyMemberId
                                                                                          }
                                                                                          label={
                                                                                              element.firstName +
                                                                                              " " +
                                                                                              element.lastName
                                                                                          }
                                                                                      >
                                                                                          {element.firstName +
                                                                                              " " +
                                                                                              element.lastName}
                                                                                      </option>
                                                                                  );
                                                                              }
                                                                          )
                                                                        : null
                                                                }
                                                                containerClassName="mt-2 w-fit"
                                                                label="Titular del activo"
                                                                className={
                                                                    "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                }
                                                                placeholder={
                                                                    true
                                                                }
                                                            />
                                                        )}
                                                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-8 mt-4">
                                                            {selected?.stock ? (
                                                                selected.name ===
                                                                "Acciones Empresas Propias" ? (
                                                                    <TextInput
                                                                        value={
                                                                            asset.extraData
                                                                                ? asset
                                                                                      .extraData
                                                                                      .stock
                                                                                : ""
                                                                        }
                                                                        errorMessage={
                                                                            errors.stock
                                                                        }
                                                                        name="stock"
                                                                        handleChange={
                                                                            handleExtraData
                                                                        }
                                                                        containerClassName="mt-2"
                                                                        label="Acción"
                                                                        className={
                                                                            "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <SelectInput
                                                                        value={
                                                                            asset.stock
                                                                        }
                                                                        errorMessage={
                                                                            errors.stock
                                                                        }
                                                                        name="stock"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        options={stocks.map(
                                                                            (
                                                                                element
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        key={
                                                                                            element.id
                                                                                        }
                                                                                        value={
                                                                                            element.id
                                                                                        }
                                                                                        label={
                                                                                            element.name
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            element.name
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                        containerClassName="mt-2"
                                                                        label="Acción"
                                                                        className={
                                                                            "shadow-sm bg-white sm:w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                        }
                                                                        placeholder={
                                                                            true
                                                                        }
                                                                    />
                                                                )
                                                            ) : null}
                                                            {selected?.investmentFund ? (
                                                                <SelectInput
                                                                    value={
                                                                        asset.investmentFund
                                                                    }
                                                                    errorMessage={
                                                                        errors.investmentFund
                                                                    }
                                                                    name="investmentFund"
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                    options={funds.map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element.id
                                                                                    }
                                                                                    value={
                                                                                        element.id
                                                                                    }
                                                                                    label={
                                                                                        element.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element.name
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    containerClassName="mt-2"
                                                                    label="Fondo"
                                                                    className={
                                                                        "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                    }
                                                                    placeholder={
                                                                        true
                                                                    }
                                                                />
                                                            ) : null}
                                                            {selected?.amount ? (
                                                                <MoneyInput
                                                                    showValue={
                                                                        true
                                                                    }
                                                                    value={
                                                                        asset.amount
                                                                    }
                                                                    errorMessage={
                                                                        errors.amount
                                                                    }
                                                                    currency={
                                                                        asset.currency
                                                                    }
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                    currencyOptions={currencies.map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element.id
                                                                                    }
                                                                                    value={
                                                                                        element.id
                                                                                    }
                                                                                    label={
                                                                                        element.acronym
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element.acronym
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    label="Monto y Moneda"
                                                                    name="amount"
                                                                />
                                                            ) : null}
                                                            {selected?.entity ? (
                                                                <SelectInput
                                                                    value={
                                                                        asset.entity
                                                                    }
                                                                    errorMessage={
                                                                        errors.entity
                                                                    }
                                                                    name="entity"
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                    options={entities.map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element.id
                                                                                    }
                                                                                    value={
                                                                                        element.id
                                                                                    }
                                                                                    label={
                                                                                        element.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element.name
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    label="Institución"
                                                                    className={
                                                                        "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                    }
                                                                    placeholder={
                                                                        true
                                                                    }
                                                                />
                                                            ) : null}
                                                            {selected?.country ? (
                                                                <SelectInput
                                                                    value={
                                                                        asset.country
                                                                    }
                                                                    errorMessage={
                                                                        errors.country
                                                                    }
                                                                    name="country"
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                    options={countries.map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element.id
                                                                                    }
                                                                                    value={
                                                                                        element.id
                                                                                    }
                                                                                    label={
                                                                                        element.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element.name
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    label="País de la Inversión"
                                                                    className={
                                                                        "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                    }
                                                                    placeholder={
                                                                        true
                                                                    }
                                                                />
                                                            ) : null}
                                                            {selected?.investmentSector ? (
                                                                <SelectInput
                                                                    value={
                                                                        asset.investmentSector
                                                                    }
                                                                    errorMessage={
                                                                        errors.investmentSector
                                                                    }
                                                                    name="investmentSector"
                                                                    handleChange={
                                                                        handleChange
                                                                    }
                                                                    options={sectors.map(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        element.id
                                                                                    }
                                                                                    value={
                                                                                        element.id
                                                                                    }
                                                                                    label={
                                                                                        element.name
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        element.name
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                    label="Sector de la Inversión"
                                                                    className={
                                                                        "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                    }
                                                                    placeholder={
                                                                        true
                                                                    }
                                                                />
                                                            ) : null}
                                                            <Checkbox
                                                                containerClassName="py-1"
                                                                name="property"
                                                                value={
                                                                    asset.property
                                                                }
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                                label="Es inmueble"
                                                            />
                                                        </div>
                                                    </div>
                                                    {selected?.nominalRate ||
                                                    selected?.capitalizationPeriod ||
                                                    selected?.rent ||
                                                    selected?.purchasePrice ||
                                                    selected?.actualPrice ||
                                                    selected?.profitOrLoss ? (
                                                        <div>
                                                            <p className="text-blue-800 font-semibold text-md mt-2">
                                                                Rentabilidad de
                                                                la inversión
                                                            </p>
                                                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-8">
                                                                {selected?.nominalRate ? (
                                                                    <TextInput
                                                                        value={
                                                                            asset.nominalRate
                                                                        }
                                                                        errorMessage={
                                                                            errors.nominalRate
                                                                        }
                                                                        name="nominalRate"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        placeholder="0.00"
                                                                        label="Tasa Nominal"
                                                                    />
                                                                ) : null}
                                                                {selected?.capitalizationPeriod ? (
                                                                    <TextInput
                                                                        value={
                                                                            asset.capitalizationPeriod
                                                                        }
                                                                        errorMessage={
                                                                            errors.capitalizationPeriod
                                                                        }
                                                                        name="capitalizationPeriod"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Periodo Capitaliz Interes"
                                                                    />
                                                                ) : null}
                                                                {selected?.rent ? (
                                                                    <div>
                                                                        <label className="text-sm font-medium text-gray-700">
                                                                            Renta
                                                                            Anual/Mensual
                                                                        </label>
                                                                        <p className="mt-2">
                                                                            {asset.rent
                                                                                ? numberWithSeparators(
                                                                                      asset.rent
                                                                                  )
                                                                                : "0"}
                                                                        </p>
                                                                    </div>
                                                                ) : null}
                                                                {selected?.purchasePrice ? (
                                                                    <TextInput
                                                                        value={
                                                                            asset.purchasePrice
                                                                        }
                                                                        errorMessage={
                                                                            errors.purchasePrice
                                                                        }
                                                                        name="purchasePrice"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Precio Compra"
                                                                    />
                                                                ) : null}
                                                                {selected?.actualPrice ? (
                                                                    <TextInput
                                                                        value={
                                                                            asset.actualPrice
                                                                        }
                                                                        errorMessage={
                                                                            errors.actualPrice
                                                                        }
                                                                        name="actualPrice"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Precio Actual"
                                                                    />
                                                                ) : null}
                                                                {selected?.profitOrLoss ? (
                                                                    <div>
                                                                        <label className="text-sm font-medium text-gray-700">
                                                                            Ganancia/Perdida
                                                                        </label>
                                                                        <p className="mt-2">
                                                                            {asset.profitOrLoss
                                                                                ? asset.profitOrLoss
                                                                                : "0"}
                                                                        </p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {selected?.ratingRiskPy ||
                                                    selected?.ratingRiskIntl ||
                                                    selected?.tfoRisk ? (
                                                        <div>
                                                            <p className="text-blue-800 font-semibold text-md mt-2">
                                                                Riesgo de la
                                                                inversión
                                                            </p>
                                                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-8">
                                                                {selected?.ratingRiskPy ? (
                                                                    <SelectInput
                                                                        value={
                                                                            asset.ratingRiskPy ===
                                                                            null
                                                                                ? 0
                                                                                : asset.ratingRiskPy
                                                                        }
                                                                        errorMessage={
                                                                            errors.ratingRiskPy
                                                                        }
                                                                        placeholder={
                                                                            true
                                                                        }
                                                                        className={
                                                                            "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                        }
                                                                        options={
                                                                            risks
                                                                        }
                                                                        name="ratingRiskPy"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Riesgo Calificadora PY"
                                                                    />
                                                                ) : null}
                                                                {selected?.ratingRiskIntl ? (
                                                                    <SelectInput
                                                                        value={
                                                                            asset.ratingRiskIntl ===
                                                                            null
                                                                                ? 0
                                                                                : asset.ratingRiskIntl
                                                                        }
                                                                        errorMessage={
                                                                            errors.ratingRiskIntl
                                                                        }
                                                                        placeholder={
                                                                            true
                                                                        }
                                                                        className={
                                                                            "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                        }
                                                                        options={
                                                                            risks
                                                                        }
                                                                        name="ratingRiskIntl"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Riesgo Calificadora Intl"
                                                                    />
                                                                ) : null}
                                                                {selected?.tfoRisk ? (
                                                                    <SelectInput
                                                                        value={
                                                                            asset.tfoRisk
                                                                        }
                                                                        errorMessage={
                                                                            errors.tfoRisk
                                                                        }
                                                                        placeholder={
                                                                            true
                                                                        }
                                                                        className={
                                                                            "shadow-sm bg-white w-full h-8 pl-2 border border-gray-300 block sm:text-sm rounded-md"
                                                                        }
                                                                        options={
                                                                            risksTFO
                                                                        }
                                                                        name="tfoRisk"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Riesgo TFO"
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    {selected?.originalDeadline ||
                                                    selected?.investmentDate ||
                                                    selected?.expirationDate ||
                                                    selected?.todaysDate ||
                                                    selected?.daysToExpiration ? (
                                                        <div>
                                                            <p className="text-blue-800 font-semibold text-md mt-2">
                                                                Horizonte de
                                                                inversión
                                                            </p>
                                                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-8">
                                                                {selected?.investmentDate ? (
                                                                    <DateInput
                                                                        defaultValue={
                                                                            asset.investmentDate
                                                                        }
                                                                        errorMessage={
                                                                            errors.investmentDate
                                                                        }
                                                                        name="investmentDate"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Fecha de Inversión"
                                                                    />
                                                                ) : null}
                                                                {selected?.expirationDate ? (
                                                                    <DateInput
                                                                        defaultValue={
                                                                            asset.expirationDate
                                                                        }
                                                                        errorMessage={
                                                                            errors.expirationDate
                                                                        }
                                                                        name="expirationDate"
                                                                        handleChange={
                                                                            handleChange
                                                                        }
                                                                        label="Fecha de Vencimiento"
                                                                    />
                                                                ) : null}
                                                                {selected?.todaysDate ? (
                                                                    <div>
                                                                        <label className="text-sm font-medium text-gray-700">
                                                                            Fecha
                                                                            de
                                                                            hoy
                                                                        </label>
                                                                        <p className="mt-2">
                                                                            {asset.todaysDate
                                                                                ? `${asset.todaysDate.substring(
                                                                                      8,
                                                                                      10
                                                                                  )}/${asset.todaysDate.substring(
                                                                                      5,
                                                                                      7
                                                                                  )}/${asset.todaysDate.substring(
                                                                                      0,
                                                                                      4
                                                                                  )}`
                                                                                : "--"}
                                                                        </p>
                                                                    </div>
                                                                ) : null}
                                                                {selected?.originalDeadline ? (
                                                                    <div>
                                                                        <label className="text-sm font-medium text-gray-700">
                                                                            Plazo
                                                                            Original
                                                                            (días)
                                                                        </label>
                                                                        <p className="mt-2">
                                                                            {asset.originalDeadline
                                                                                ? asset.originalDeadline
                                                                                : "0"}
                                                                        </p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
